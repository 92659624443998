import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as Sentry from "@sentry/react";
import { lazy } from "react";
import AppProvider from "./AppContext";
import Header from "./components/header";
import LoadingScreen from "./components/loader/LoadingScreen";
import Sidebar from "./components/menu/sidebar";
import MobileBottomNav from "./components/navBottom";
import { history } from "./helpers/history";
import { RootState } from "./store";
import { isNullOrUndefined } from "./utils";

const Dashboard = lazy(() => import("./views/Dashboard"));
const Lobby = lazy(() => import("./views/Lobby"));
const Download = lazy(() => import("./views/Download"));
const SupportModal = lazy(() => import("./components/SupportModal"));
const Promotion = lazy(() => import("./views/Promotion"));

// Sentry.init({
// 	dsn: "https://e1ba7e822d3792725e1a371c28149206@o392754.ingest.us.sentry.io/4508195260137472",
// 	integrations: [new Sentry.BrowserTracing()],
// 	tracesSampleRate: 1.0,
// });

const App: React.FC = () => {
	const { i18n } = useTranslation();
	const { loading, language } = useSelector((state: RootState) => state.store);
	const { userProfile } = useSelector((state: any) => state.userProfile);

	const routes = [
		{
			path: "/",
			exact: true,
			name: "Home",
			component: !isNullOrUndefined(userProfile) ? Lobby : Dashboard,
			access: true,
		},
		{ path: "/promotion", name: "Promotion", component: Promotion, access: true },
		{ path: "/home", name: "Home", component: Dashboard, access: true },
		{
			path: "/lobby",
			name: "Lobby",
			component: Lobby,
			access: !isNullOrUndefined(userProfile),
		},
		{ path: "/download", name: "Download", component: Download, access: true },
	];

	useEffect(() => {
		if (i18n.language !== language) i18n.changeLanguage(language);
	}, [language]);

	const menu = routes.map((route, index) => {
		return route.component && route.access === true ? (
			<Route
				key={index}
				path={route.path}
				exact={route.exact}
				render={(props: any) => <route.component {...props} />}
			/>
		) : null;
	});

	return (
		<div className="wrapper">
			<Router history={history}>
				<AppProvider>
					<Suspense fallback={<LoadingScreen loading={loading} />}>
						{loading && <LoadingScreen loading={loading} />}
						<Sidebar />
						<SupportModal />
						<div className="sidebar-right">
							<Header />
							<Switch>{menu}</Switch>
						</div>
					</Suspense>
					<ToastContainer autoClose={3000} theme="dark" />
					<MobileBottomNav />
				</AppProvider>
			</Router>
		</div>
	);
};

export default App;
